:root {
    --nav-height: 50px;
    --header-height: 80px;
    --main-content-height: calc(100vh - var(--nav-height));
    --graph-height: calc(100vh - var(--nav-height) - var(--header-height));
    --bs-primary: #2C3E50;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #f7f7f7;
    font-family: 'Raleway', sans-serif;
    height: 100vh;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
}

@media only screen and (min-width: 1000px) {
    nav {
        height: var(--nav-height);
    }
}

main {
    min-height: var(--main-content-height);
    height: var(--main-content-height);
    background: #2C3E50;
}

.shadow {
    border: none;
    border-radius: 10px;
    background: #f7f7f7;
    box-shadow: 5px 5px 6px #ebebeb,
    -5px -5px 6px #ffffff;
}

.toast {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 100;
}

.nav-link {
    color: #d3dae1 !important;
}

.chart-container {
    min-height: var(--graph-height);
    height: var(--graph-height);
}

p.dropdown-item {
    cursor: pointer;
}
